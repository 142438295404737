import React from 'react'
import classes from './style/internships.module.css'
import CircleIcon from '@mui/icons-material/Circle';

const Internships = (props) => {
function formatDate(date)
{
  return(date?date.slice(0,10):null)
}
  return (
    <div className={classes.certificates}>
       <div className={classes.maintitle}>
            <div className={classes.title}>
            <div style={{color:"#17289C"}}><CircleIcon /></div>
            <div>{props.position}</div>
            </div>
            <div className={classes.duration}><b>From</b> {formatDate(props.startDate)} <b>To</b> {formatDate(props.endDate)}</div>
       </div>
       <div className={classes.body}>
       <div style={{marginBottom:"10px"}}>{props.place}</div>
       <div>{props.description}
       </div>
       </div>
    </div>
  )
}

export default Internships