import React from 'react'
import classes from './style/questioner.module.css'
import Slider from '../components/SliderR'
import Instructions from '../components/Instructions'
import { useState } from 'react'

const Questioner = ()=>{
    const [start,setstart]=useState(0);

    const clickhandler =()=> {
        setstart(1);
    }

    const pclickhandler =()=> {
        setstart(0);
    }

    return(
        <div className={start?classes.body1:classes.body}>
            {start?<Slider prevClick={pclickhandler}/>:<Instructions click={clickhandler}/>}
        </div>
    );
}

export default Questioner