import classes from "./loader.module.css"
import React from 'react' 
import { Blocks } from  'react-loader-spinner'

const Loader = () => {
    return(
        <div className={classes.loader}> 
            <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            /> 
        </div>
    );
}

export default Loader


