import { Switch, Route,Redirect  } from "react-router-dom"
import Nav from './components/Nav'
import { useParams } from 'react-router-dom';
import UserForm from "./components/UserForm";
import ViewProfile from "./components/ViewProfile";
import AboutISI from "./pages/AboutISI";
import Auth from "./pages/Auth";
import Home from './pages/Home';
import Profile from './pages/Profile';
import CompanyProfile from './pages/CompanyProfile'
import Questioner from './pages/Questioner';
import EditUserInfo from "./components/EditUserInfo";
import { ClassNames } from "@emotion/react";
import CompanyForm from "./components/CompanyForm";
import EditCompanyInfo from "./components/EditCompanyInfo";


function App() {
    return (
        <div className="App">
            <Nav />
            <Switch>
                <Route path='/' exact>
                    <Home />
                </Route>
                <Route path='/auth' exact>
                    <Auth />
                </Route>
                <Route path='/auth/change-password/:id'>
                    <Auth resetPass={true} />
                </Route>
                <Route path='/about'>
                    <AboutISI />
                </Route>
                <Route path='/Q'>
                    <Questioner />
                </Route>
                <Route path='/Profile'>
                    <Profile />
                </Route>
                <Route path='/Company'>
                    <CompanyProfile />
                </Route>
                <Route path='/Userform'>
                    <UserForm />
                </Route>
                <Route path='/EditUserInfo'>
                    <EditUserInfo />
                </Route>
                <Route path='/EditCompanyInfo'>
                    <EditCompanyInfo />
                </Route>
                <Route path='/Companyform'>
                    <CompanyForm />
                </Route>
                <Route path='/viewProfile/:id'>
                    <ViewProfile />
                </Route>
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </div>
    );
}

export default App;
