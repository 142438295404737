import React from 'react'
import classes from './style/findaboss.module.css'
import { useHistory } from "react-router-dom";
import {Formik, Field, ErrorMessage, Form, FieldArray } from 'formik'
import { useState } from 'react';
import Results from './Results';
import 'animate.css';
import * as Yup from 'yup'

let results=[0,0,0,0];
const Jobmatch = (props) => {
    const [res,setres]=useState(0);

    function pageScroll() {
        window.scrollBy(0,700);
    }

    const initialValues = {
        jobtitle: "",
        jobdesc: "",
        options: [],
    }

    const onformSubmit = (data)=>{
        if(data.options.length != null && data.options.length<11){
            for(let i=0; i<data.options.length; i++){
                let x = data.options[i];
                if(x==1 || x==3 || x==4 || x==8 || x==15 || x==24){
                    results[0]++;
                }
                else if(x==0 || x==5 || x==9 || x==10 || x==16 || x==22 || x==23){
                    results[1]++;
                }
                else if(x==2 || x==11 || x==6 || x==14 || x==17 || x==21){
                    results[2]++;
                }
                else{
                    results[3]++;
                }
            }
            setres(1);
            pageScroll();
        }
        if(data.options.length>10){
            setres(2);
        }
    }
    
    const history = useHistory();

    return (
        <div className={classes.Boss}>
            <div className={classes.form}>
            <h1> Job Matching!</h1>
            <div className={classes.info}>
                Dear employer,
                <ul>
                    <li><span>This checklist helps us know about the personal competencies that you are searching for in candidates who could best fit as heads.</span></li>
                    <li>Try to be accurate in choosing the most important features among those listed below to avoid misleading results</li>
                    <li>Lastly, The Matchmaker will give you a guide about the ISI’s most appropriate styles for that job position. </li>                
                </ul>
            </div>
            <h3> Job position Data</h3>
                <Formik initialValues={initialValues} onSubmit={values=>onformSubmit(values)}>
                {
                    ({values})=>
                    <Form className={classes.shape}>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>What is the “Job Title” of the Head you need?</label>
                            <Field as='textarea' className={classes.input} type="text" name="jobtitle" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='jobtitle' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>What is the “Job Description” of the Head you need? </label>
                            <Field as='textarea' className={classes.input} type="text" name="jobdesc" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='jobdesc' component='span'/>
                        </div>

                        <h2>Choose from the following list the most 10 fitting personal features that you find crucial to be present in the candidates applying for such a position.</h2>
                        <div className={classes.options} >
                            <label className={classes.main}>
                                {props.questions[0].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="0"/>
                                <span className={classes.geekmark}></span>
                            </label> 
                            <label className={classes.main}>
                                {props.questions[1].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="1"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[2].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="2"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[3].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="3"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[4].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="4"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[5].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="5"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[6].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="6"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[7].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="7"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[8].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="8"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[9].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="9"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[10].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="10"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[11].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="11"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[12].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="12"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[13].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="13"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[14].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="14"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[15].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="15"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[16].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="16"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[17].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="17"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[18].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="18"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[19].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="19"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[20].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="20"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[21].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="21"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[22].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="22"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[23].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="23"/>
                                <span className={classes.geekmark}></span>
                            </label>
                            <label className={classes.main}>
                                {props.questions[24].question}
                                <Field type="checkbox" className={classes.checkbox} name="options" value="24"/>
                                <span className={classes.geekmark}></span>
                            </label>
                        </div>


                        <div className={classes.f}>

                            <button type="submit">
                                Submit
                            </button>
                            {res==2?<h4 style={{color:"red", margin:"10px", fontSize:"11px"}}>Sorry, more than 10 choices</h4>:null}
                     </div>
                    </Form> 
                }
        </Formik>
        {res?<h3 class="animate__fadeInUp"> Results</h3>:null}
        </div>
        {res?<Results n={0} f={(results[0]*100)/7} s={(results[1]*100)/7} t={(results[2]*100)/7} fo={(results[3]*100)/7}/>:null}
        </div>
    )
}
export default Jobmatch