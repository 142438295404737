import React from 'react'
import { useState } from 'react';
import classes from './style/auth.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import * as Yup from 'yup'
import axios from 'axios';
import Pop from './Pop';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
const ForgotPass = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [pop, setPop] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [openModal, setOpenModal] = useState(false);
    function handleModalClose() {
        setOpenModal(false);

    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "49.5%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "0px",
        boxShadow: 24,
        p: 4,
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        opacity: "80%"
    };
    const initialValues = {
        email: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().min(3).email('Invalid email address').required(" Email field is required"),
    })
    const onSubmit = (data, { resetForm }) => {
        /////////////////////
        //TODO Change Route//
        /////////////////////
        console.log(data);
        axios.patch("https://api.matchmaker-eg.com/api/user/change-password", data).then((res) => {
            setOpenModal(true);
            setErrorMsg("")
            resetForm()
        }).catch((err) => {
            if (err.response.data.error === "Error: email is not verified ") {
                setPop(true)
            }
            if (err.response.data.error === "Error: User is not found") {
                setErrorMsg("User Doesn't Exist")
            }

            resetForm()
        })
    }
    function clearError() {
        setErrorMsg("")
    }
    return (
        <div className={classes.container}>
            <div className={classes.center}>
                <div className={classes.box1}>
                    <h1>Forgot Password</h1>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        <Form>
                            <div className={classes.fieldContainer}>
                                <Field className={classes.field} name='email' placeholder="Email address" autoComplete="off" />
                                <label className={classes.label}> Email address</label>
                                <ErrorMessage name='email' component="span" />
                            </div>
                            <div className={classes.errorMsgFP}>{errorMsg}</div>
                            <div className={classes.btn}>
                                <button onClick={clearError} type='submit'>Send</button>
                            </div>
                        </Form>
                    </Formik>
                    <Modal open={openModal} onClick={handleModalClose}>
                        <Box sx={style}>
                            <h1 className={classes.boxParagraph}>
                                An Email to reset your password was sent to you
                            </h1>
                        </Box>
                    </Modal>
                </div>
                <div className={classes.box2}>
                    <p>Go back to <span onClick={props.signinHandeler}> login</span></p>
                </div>
            </div>
        </div>
    )
}

export default ForgotPass
