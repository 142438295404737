import classes from './style/userform.module.css';
import React from "react";
import {Formik, Field, ErrorMessage, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import axios from 'axios';
import { useHistory } from "react-router-dom";


function EditCompanyInfo () {   

    let token = sessionStorage.getItem("tokenValue");
    const [ProfileInfo,setProfileInfo ] = React.useState([]);

    function scrollUp()
    {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
const history = useHistory();

async function GetUserProfile() {
    var config = {
        method: 'get',
        headers: {Authorization:"Bearer "+token }
      };
    let response = '';
  try {
    response = await axios.get('https://api.matchmaker-eg.com/api/company/'+sessionStorage.getItem("ID"),config).then((res) => res.data);

    setProfileInfo(response);


    return (response);
  } catch (error) {
    if (error.response) {
      return (error.response);
    }
  }
  return (response);
}

React.useEffect(() => {
    (async () => {
      const resp = await GetUserProfile();
      setProfileInfo(resp);
      console.log(ProfileInfo);
    })();
  }, []);

  function formatDate(date)
{
    return(date?date.slice(0,10):null)
}
  
// const contactsData = ProfileInfo.contactPersonData_availableServices?.map((obj)=>{  
//     return({
//             key:obj._id,
//             name: obj.name,
//             }
//         )
// })


const initialValues = {
    //Company basic info
    companyName: ProfileInfo.companyName,
    companyAddress: ProfileInfo.companyAddress,
    companyPhone: ProfileInfo.companyPhone,
    industry: ProfileInfo.industry, 
    country: ProfileInfo.country, //Needed to be added
    URL: ProfileInfo.URL,

    //Social Accounts
    facebook: ProfileInfo.facebook, //b
    linkedin: ProfileInfo.linkedin, //b
    instagram: ProfileInfo.instagram, //b

    //Other Data
    companySize: ProfileInfo.companySize, 
    legalEntity: ProfileInfo.legalEntity,
    olegalEntity: ProfileInfo.olegalEntity, //b
    commercialRegistrationNumber: ProfileInfo.commercialRegistrationNumber,
    registrationYear: formatDate(ProfileInfo.registrationYear),

    //Contact person data 
    contactPersonData_FullName: ProfileInfo.contactPersonData_FullName,
    contactPersonData_IDNumber: ProfileInfo.contactPersonData_IDNumber,
    contactPersonData_PositionInTheCompany: ProfileInfo.contactPersonData_PositionInTheCompany,
    contactPersonData_mobileNumber: ProfileInfo.contactPersonData_mobileNumber,
    contactPersonData_emailAddress: ProfileInfo.contactPersonData_emailAddress,
    contactPersonData_availableServices: "", //Not needed
    contactPersonData_subscriptionOptions: "", //Not needed
}

const validationSchema = Yup.object().shape({
    /**/
    })
        
    
    const onformSubmit = (data)=>{
        
    
        axios.put(`https://api.matchmaker-eg.com/api/company`, data
        ,{ headers: {Authorization: `Bearer ${sessionStorage.getItem("tokenValue")}`}}).then((res)=>{
        })
    
    
        history.push("/Company/Profile")
    
    }
    
    const country = ["Afghanistan",
        "Albania"]
    
    
    const Csize = ["<10", ">10", ">50",">100", ">300", ">500"];
    const leg = ["Corporation", "Cooperative", "Partnerships", "Sole Traders", "Limited Liability Company", "Non registered"];
    function dropDownMenu(option,index) {
        return (
            <option  key = {index}className={classes.option} value={option}>{option}</option>
        )
    }
        function scrollUp()
        {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    
    
        return (
            <div className={classes.body}>
    
            <Formik initialValues={initialValues}  enableReinitialize validationSchema={validationSchema} onSubmit={values=>onformSubmit(values)}>
                  {
                    ({values})=>
                    <Form className={classes.shape}>
                        <div className={classes.head}>
                            <h3> Edit Information </h3>
                        </div>
                    <div className={classes.userbox}>
                        <div className={classes.left}>
                            <h1> Company <br/> Basic Info </h1>
                        </div>
                        <div className={classes.right}>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Company/Organisation Name</label>
                            <Field className={classes.input} type="text" name="companyName" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='companyName' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Company Address</label>
                            <Field className={classes.input} type="text" name="companyAddress" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='companyAddress' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Company Phone Number</label>
                            <Field className={classes.input} type="text" name="companyPhone" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='companyPhone' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Industry Type</label>
                            <Field className={classes.input} type="text" name="industry" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='industry' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Country</label>
                            <Field as="select" className={classes.input} type="text" name="country" placeholder="Type here" disabled>
                                <option value="" disabled="disabled">Select your country</option>
                                {country.map((g, index)=> dropDownMenu(g,index))}
                            </Field>
                            <ErrorMessage className={classes.Err} name='country' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Official website URL</label>
                            <Field className={classes.input} type="text" name="URL" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='URL' component='span'/>
                        </div>
                        </div>
                    </div>
                    
                    <div className={classes.userbox} style={{minHeight:200}}>
                        <div className={classes.left}>
                            <h1> Social <br/>  Accounts </h1>
                        </div>
                        <div className={classes.right}>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Facebook</label>
                            <Field className={classes.input} type="text" name="facebook" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='facebook' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Linkedin</label>
                            <Field className={classes.input} type="text" name="linkedin" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='linkedin' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Instagram</label>
                            <Field className={classes.input} type="text" name="instagram" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='instagram' component='span'/>
                        </div>
                        </div>
                    </div>
    
                    <div className={classes.userbox} style={{minHeight: 200}}>
                        <div className={classes.left}>
                            <h1> Other Data </h1>
                        </div>
                        <div className={classes.right}>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Company Size</label>
                            <Field as="select" className={classes.input} type="text" name="companySize" placeholder="Type here">
                              <option value="" disabled="disabled">Select from list ...</option>
                              {Csize.map((h, index)=> dropDownMenu(h,index))}
                            </Field>
                            <ErrorMessage className={classes.Err} name='companySize' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Legal Entity</label>
                            <Field as="select" className={classes.input} type="text" name="legalEntity" placeholder="Type here">
                              <option value="" disabled="disabled">Select from list ...</option>
                              {leg.map((f, index)=> dropDownMenu(f,index))}
                            </Field>
                            <ErrorMessage className={classes.Err} name='legalEntity' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Other Legal Entity (Specify)</label>
                            <Field className={classes.input} type="text" name="olegalEntity" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='olegalEntity' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Commercial Registration Number</label>
                            <Field className={classes.input} type="text" name="commercialRegistrationNumber" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='commercialRegistrationNumber' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Registration Year</label>
                            <Field className={classes.input} type="date" name="registrationYear" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='registrationYearf' component='span'/>
                        </div>
                        </div>
                    </div>
    
    
                    <div className={classes.userbox}>
                        <div className={classes.left}>
                            <h1> Contact <br/> Person Data </h1>
                        </div>
                        <div className={classes.right}>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Full Name</label>
                            <Field className={classes.input} type="text" name="contactPersonData_FullName" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='contactPersonData_FullName' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>ID Number</label>
                            <Field className={classes.input} type="text" name="contactPersonData_IDNumber" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='contactPersonData_IDNumber' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Position in Company</label>
                            <Field className={classes.input} type="text" name="contactPersonData_PositionInTheCompany" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='contactPersonData_PositionInTheCompany' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Mobile Number</label>
                            <Field className={classes.input} type="text" name="contactPersonData_mobileNumber" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='contactPersonData_mobileNumber' component='span'/>
                        </div>
                        <div className={classes.inputbox}>
                            <label className={classes.label}>Email Address</label>
                            <Field className={classes.input} type="text" name="contactPersonData_emailAddress" placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name='contactPersonData_emailAddress' component='span'/>
                        </div>
                        </div>
                    </div>
                        <div className={classes.update}>
                                <button type="submit">
                                    Update
                                </button>
                         </div>
                    </Form> 
                    }
          </Formik>
    
            </div>
        );
    }
    
export default EditCompanyInfo;