import classes from "./style/about.module.css"
import React from 'react' 
import img1 from '../Img/Img1.png'
const About = () => {
    return(
    <div >
    <div className={classes.comp}>
        <h1 className={classes.titlea}>About</h1>
        <div className={classes.subdis}>
        <p> The Matchmaker is the linking bridge between job seekers and recruiters. It offers an Applicant Tracking System (ATS) for candidate sourcing and creates “Talent Profiles” (career page) for experts and talents searching for job opportunities. At the same time, It allows Job Posting for recruiting companies, employers, HR managers and decision makers and help them scan, sort and rank talents for selecting the best candidates for successful hiring.</p>
        </div>
        <div className={classes.info}>
        <div className={classes.left}>
        <p> Predictive hiring is the process of using personality testing to gain 78% accuracy in predicting successful hires. The Matchmaker is using the most recently invented tool for personality assessment; the Intellectual Style Inventory (ISI). The ISI has got great precision, convenience and reliability and the Matchmaker is the exclusive portal through which job applicants can obtain their ISI personality report and discover their hidden talents. Moreover, the Matchmaker is offering “Person-Job Fit” for employers as it creates ‘Description Cards” that identifies the personal characteristics of the job applicants that would perfectly match with the job competencies for the target positions. This process is the first in its nature to be applied in the field of recruitment and selection. It is the only proven method that facilitates predictive hiring and increases the success rate of proper hiring.</p>
        </div>
        <div className={classes.right}>
            <img src={img1} alt="User1"></img>
        </div>
        </div>
    </div>
    <div className={classes.cube}>
        <li></li>
        <li></li>
        <li></li>
    </div>
    </div>
    );
}

export default About