import imga from "../Img/avatars/accomplisher.jpg"
import imgb from "../Img/avatars/DemonstrativeMind.jpg"
import imgc from "../Img/avatars/Factual.jpg"
import imgd from "../Img/avatars/Foreseeing.jpg"
import imge from "../Img/avatars/Theorist.jpg"
import imgf from "../Img/avatars/Selfreflective.jpg"
import imgg from "../Img/avatars/planner.jpg"
import imgh from "../Img/avatars/Motivator.jpg"
import chartimga from "../assets/Charts/ISI_theorist.png"
import chartimgb from "../assets/Charts/ISI_Forseeing.png"
import chartimgc from "../assets/Charts/ISI_Self-Reflective.png"
import chartimgd from "../assets/Charts/ISI_Factual.png"
import chartimge from "../assets/Charts/ISI_DemonstrativeMind.png"
import chartimgf from "../assets/Charts/ISI_Motivator.png"
import chartimgg from "../assets/Charts/ISI_Accomplisher.png"
import chartimgh from "../assets/Charts/ISI_Planner.png"

const products = [
{
    name: 'Theorist',
    img : imge,
    description: "The Theorist preferentially uses his front left cortical lobe. He is typically the perfect judge for assumptions. He uses the syllogism to reach certainty but could be deceived by false inputs. He generally tends to analysis and comparing between alternatives in an abstract world. He is perfect at critical thinking and is logical in problem solving. He has got the talent to draw his conclusions in a perfect syntax since he has got a great sense of the meaning of a word and the perfect talent of composing meaningful sentences. The read and written material is highly inspiring for the theorist.",
    id:0,
    chartimg:chartimga,
    result: "The Theorist is the perfect judge for assumptions, good at analysis and skillful in comparing between alternatives in an abstract world.",
    color:"#F7A131"
},
{
    name: 'Foreseeing',
    img : imgd,
    description: "The Foreseeing preferentially uses his front right cortical lobe. He is always playing with thoughts, ideas and possibilities. He is clever in catching opportunities and can easily predict the unseen. He is non-realistic in the eyes of everyone and even miss understood in most cases. But the time moves and his expectations come true. He is the idea generator but at the same time he is not the person who can apply them. He works perfectly despite ambiguity and doesn’t pay much attention to details. He gathers observations from here and there and set it free for his imagination, then finally he comes up with something new. He is very much inspired by pictures and diagrams and perfectly understands what he can see or imagine",
    id:1,
    chartimg:chartimgb,
    result: "The Foreseeing is always playing with thoughts, ideas and possibilities, clever in catching opportunities, and can easily predict the unseen. ",
    color:"#009447"
},
{
    name: 'Self-reflective',
    img : imgf,
    description: "The Self-reflective preferentially uses his base right cortical lobe. His main concern is in ‘harmony’ and ‘the self’. He relies on everything that he is doing or learning by himself and has got the ultimate confidence in his own experience. He evaluates things according to his like/dislike value system that he builds throughout his life experiences. He searches for harmony in everything and feels at ease whenever peace is spread. He is practical, people oriented and very clever in both introspection and intraspection. He cares a lot for how others feel and can perfectly read gestures and faces. He has got unique emotional intelligence talents yet his world is such unique and his choices are unpredicted",
    id:2,
    chartimg:chartimgc,
    result: "The Self-reflective main concern is in ‘harmony’ and ‘the self’, trust his own experience, and evaluates things according to his personal like/dislike value system. ",
    color:"#008DA1"
},
{
    name: 'Factual',
    img : imgc,
    description: "The Factual person preferentially uses his base left cortical lobe. He is strict, disciplined and very structured. He is concrete, realistic and unfamiliar with ambiguity. He is highly attentive to details and likes to do things in the same -previously tested way- each time. He is non-risky and can perfectly evaluate and respect the status quo. He is materialistic, cautious, very much organized and always referred to as the perfectionist",
    id:3,
    chartimg:chartimgd,
    result: "The Factual is concrete, realistic, not familiar with ambiguity, highly attentive to details, and likes to do things in the same -previously tested way- each time. ",
    color:"#E96C8C"
},
{
    name: 'Demonstrative Mind ',
    img : imgb,
    description: "The demonstrative mind is a front brain learner who is more abstract than concrete. This mind is more interested in the “why” and “how” more than the “where” and “when”. It perfectly deals with ideas, thoughts, concepts and principles. It is qualified in setting short and long term goals, systems evaluation, strategic thinking and building the far future plans. As an abductive mind that excels in both deduction and induction, the demonstrative mind masters climbing up and moving down the logical reasoning ladder to reach certainty and to ensure assent. The Demonstrative mind fits more as a leader than as a manager since it is not interested in details or in performing the job by himself. This mind tends to read the scene and direct the process towards the right position",
    id:4,
    chartimg:chartimge,
    result: "The demonstrative mind is abstract, qualified in setting short and long term goals, systems evaluation, strategic thinking and building the far future plans. This mind tends to read the scene and craft the vision.",
    color:"#A2C672"
},
{
    name: 'Motivator',
    img : imgh,
    description: "The Motivator is a right brain learner who lives in the world of people and ideas. This mind is more interested in the “what” and “who” more than the “why” and “how”. The Motivator mind is an endless source of inspiration and a turbulent flood of feelings and emotions. People always like to listen to his words and to believe in his choices. On the other hand he can perfectly discover the talents of others and pick up opportunities hidden by the fog. The Motivator is the treasure miner that leads people and ideas to distant eras by his prosperous imagination. The Motivator is a leader more than a manager since he is often far from reality and trapped in his thoughts and speculations",
    id:5,
    chartimg:chartimgf,
    result: "The Motivator is an endless source of inspiration and a turbulent flood of feelings and emotions. People always like to listen to his words and to believe in his choices. the Motivator is skillful in discovering talents and in picking up opportunities hidden by the fog. ",
    color:"#2FABAA"
},
{
    name: 'Accomplisher',
    img : imga,
    description: "The Accomplisher mind is a base brain learner who is more concrete than abstract. This mind is more interested in the “where” and “when”. The Accomplisher is perfectly a ‘doer’ who always tend to get things done. This mind is a perfectionist who cares for details and is highly down to earth. This mind relies a lot on his gut feelings and don’t spend a lot of time trying to explaining them. He is more involved in the practical life and more focusing on the real situation on the ground. The Accomplished is a first class executive manager. He is far away from leadership and prefer to obey rules, schedules, plans, instructions … etc. and not to generate them",
    id:6,
    chartimg:chartimgg,
    result: "The Accomplisher mind is the best ‘doer’, perfectionist, cares for details, listen to his gut feelings, and perfectly obey rules, schedules, plans, instructions … etc.",
    color:"#9C73B3"
},
{
    name: 'Planner',
    img : imgg,
    description: "The Planner mind is a left brain learner who is mostly task oriented. This mind is more interested in the procedures and processes. It excels in shaping evident opportunities and investing in actual resources. This mind is highly ordered and systematic and superior at valuation. The Planner is inclined towards details, facts and concrete foundations. This mind likes to put things in place and to follow well-structured stepwise plans that are having clear objectives and defined outcomes. This mind is the best as a manager and is able to get things done in their best versions. Employers can perfectly rely on him but sometimes he is harsh on his employees",
    id:7,
    chartimg:chartimgh,
    result: "The Planner is highly interested in ‘procedures’ and ‘processes’. the Planner's  mind is  systematic and structured, and superior at valuation. Planners always follow well-structured stepwise plans with clear objectives and defined outcomes and bring things up in its best version.",
    color:"#EAB264"
}
]

export default products
