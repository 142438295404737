import React from 'react'
import classes from './style/fullprofile.module.css'
import CircleIcon from '@mui/icons-material/Circle';


const Skills = (props) => {
  return (
        <div className={classes.title}>
            <div style={{ color: "#17289C" }}><CircleIcon /></div>
            <p>{props.skill}</p>
          </div>
  )
}

export default Skills