import classes from "./style/work.module.css"
import React from 'react' 
import img2 from '../Img/img3.jpg'

const Works2 = () => {
    return(
    <div className={classes.con}>
    <div className={classes.comp}>
        <h1 className={classes.titlea}>How it works ?</h1>
        <h2 className={classes.subtitle}>For Self-recruiting Companies & Enterprises</h2>
        <div className={classes.work}>
            <img src={img2} alt="User2"></img>
        </div>
        <ul className={classes.cardlist}>
        <li className={classes.card}>
            <h1 className={classes.number}>1</h1>
            <p className={classes.par}> Helps companies in building a “Description Card” for each job position (title) in the company. This identifies the best fitting candidate’s profile in terms of personal competencies. </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>2</h1>
            <p className={classes.par}> Offers a customized search that facilitates the selection from among job applicants and the other profiles available on the system. </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>3</h1>
            <p className={classes.par}> Makes the full data of self-joining applicants (job seekers) completely available for sourcing. Sorted by best matching candidates that are notified for further approach </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>4</h1>
            <p className={classes.par}> Introduces a battery of psychometric tools –in Arabic and English- that includes aptitude and cognitive testing, different kinds of intelligences, management styles, and negotiation skills.  </p>
        </li>
        <li className={classes.card}>
            <h1 className={classes.number}>5</h1>
            <p className={classes.par}> Provides a detailed ISI report for all applicants that can help in exploring the intrinsic strengths/ weaknesses of each candidate and used in interviews to overcome the misleading gut-feeling driven decisions. </p>
        </li>
        </ul>
    </div>
    <div className={classes.diagonalbox2}>
            <div className={classes.content}> ... </div>
    </div>
    </div>
    );
}

export default Works2