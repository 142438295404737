import React from 'react'
import McqChoices from './McqChoices';
import classes from './style/sliderobj.module.css'

const SliderObj = (props)=>{
    return(
        <div className={classes.txt}>
                <h1> {props.msg} </h1>
                <McqChoices array={props.arr} index={props.ind}/>
              </div>
    );
}

export default SliderObj