import classes from "./style/Isi.module.css"
import React from 'react'
import isi1 from "../assets/MainLogo/Isi2.jpg"
import isi2 from "../assets/MainLogo/Isi1.jpg" 
import { useState } from "react"

const Isi = () => {
    const[is,setis]=useState(false);

    function clickhandler () {
        if (is)
        {
            setis(false);
        }
        else
        {
            setis(true);
        }
        
    }

  return (
   <div className={classes.isi}>
    <h1> Our Technology </h1>
     <div className={classes.infContainer}>
        <div className={classes.txtContainer}>
            {is ? <img src={isi2} onClick={clickhandler} /> : <img src={isi1} onClick={clickhandler}/> }
        </div>
        <div className={classes.txtContainer}>
            <h4>The key to successful recruiting is still missing, yet it is sought to be in Predictive Hiring which depends on a good Personality Test. Specialists find the available personality tools non satisfactory on different levels and here comes the value of the Matchmaker underlying technology; the Intellectual Style Inventory (ISI)</h4>
            <div className={classes.b}>
                <button> More Information</button>
               
            </div>
        </div>
      </div>
   </div>
  )
}

export default Isi