import React, { useState} from 'react';
import { SliderData } from './SliderData.js';
import classes from './style/SliderR.module.css'
import SliderObj from '../components/SliderObj';
import axios from 'axios';
import SuccessModal from './SuccessModal.js';

var array = new Array(48);
array.fill(0);
var previousindicator = 0;


const SliderR = (props) => {
  const [current, setCurrent] = useState(0);
  const [progress, setprogress] = useState(0);
  const [missing, setmissing] = useState(0);
  const [finished, setfinished] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  function handleOpen() 
  {
    setOpenModal(true)
  }

  function handleClose() 
  {
    setOpenModal(false)
  }
  const length = SliderData.length;
  

  function increase(){
    var p=progress;
    if (p<100)
      {
        setprogress(p+17);
      }
  }

  const nextSlide = () => {
    var approve=1;
    for (let index = current*8; index < ((current*8)+8); index++) {
      const element = array[index];
      if (element===0)
      {
        approve=0;
        setmissing(1);
        break;
      }
    }
    if (current<5 && approve)
    {
      setCurrent(current === length - 1 ? 0 : current + 1);
      if (previousindicator>0)
      {
        previousindicator=previousindicator-1;
      }
      else
      {
        increase(); //Function for the progress bar
        setmissing(0);
      }
    }
    
    if (current===5 && approve)
    {
      setfinished(1);
      setprogress(100);
      handleOpen();
      const result =  array
      
        axios.post("https://api.matchmaker-eg.com/api/company/test", {result} 
        ,{ headers: {Authorization: `Bearer ${sessionStorage.getItem("tokenValue")}`}}).then((res)=>{
        console.log(res);
      }).catch((err)=>{
        console.log(err)
      })
    }
  };

  const prevSlide = () => {
    if (current>0)
    {
      setCurrent(current === 0 ? length - 1 : current - 1);
      previousindicator=previousindicator+1;
      setfinished(0);
    }
  };

  if (!Array.isArray(SliderData) || SliderData.length <= 0) {
    return null;
  }

  return (
  <section className={classes.slider} id="top">
      {SliderData.map((slide, index) => {
        return (
          <div
            className={index === current ? 'slideactive' : 'slide'}
            key={index}
          >
            {index === current && (
              <div className={classes.Q8}>
                <div className={classes.bar}> 
                  <div className={classes.progressbarC}> <p className={progress<50? classes.b: classes.w}> {progress+"%"} </p>
                  {progress?<span className={classes.progressbar} style={{width: (progress+2)+"%"}}></span>:null}
                  </div>
                </div>
                {missing?<h3 className={classes.missing}> * There are missing questions * </h3>:null}
                {finished?<SuccessModal msg="Questionnaire" link="/Profile/Avatar"/>:null}
                <SliderObj msg={slide.msg1} arr={array} ind={index*8}/>
                <SliderObj msg={slide.msg2} arr={array} ind={(index*8)+1}/>
                <SliderObj msg={slide.msg3} arr={array} ind={(index*8)+2}/>
                <SliderObj msg={slide.msg4} arr={array} ind={(index*8)+3}/>
                <SliderObj msg={slide.msg5} arr={array} ind={(index*8)+4}/>
                <SliderObj msg={slide.msg6} arr={array} ind={(index*8)+5}/>
                <SliderObj msg={slide.msg7} arr={array} ind={(index*8)+6}/>
                <SliderObj msg={slide.msg8} arr={array} ind={(index*8)+7}/>
              </div>
              

            )}
          </div>
        );
      })}
      {(current===0)?
      <div className={classes.adjustbtn}>
        <div sx= {{ fontSize:'60px'}} className={classes.leftarrow} onClick={props.prevClick} > <p> Previous </p> </div>
        <a href="#top" > <div sx= {{ fontSize:'60px'}} className={classes.rightarrow} onClick={nextSlide} > <p> Next </p> </div></a>
      </div>
      :
      <div className={classes.adjustbtn}>
        <div sx= {{ fontSize:'60px'}} className={classes.leftarrow} onClick={prevSlide} > <p> Previous </p> </div>
        {!finished? <a href="#top" > <div sx= {{ fontSize:'60px'}} className={classes.rightarrow} onClick={nextSlide} > <p> Next </p> </div> </a>:
        <div sx= {{ fontSize:'60px'}} className={classes.donebutton} onClick={nextSlide } >  <p> Done </p>  
        </div>}
      </div>}
    </section>
  );
};

export default SliderR;