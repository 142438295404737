
import {createStore} from 'redux'
const dataReducer = (state = {login: false ,username: "",email: "" , id:0}, action) =>{
    
    if(action.type=== "signOut"){
        return {
            login:false,
            id: 0,
            email: ""
        }
    }
    if(action.type ==="Login" ){
        return {
            login:action.login,
            id: action.id,
            email: ""
            
        }
    }

    if(action.type === "check"){
        return {
            login:false,
            id: 0,
            email: action.email
        }
    }

   
    return state;
    
}
function loadState(){
    const state = sessionStorage.getItem('Data')
    if(state !== null){
        return JSON.parse(state);
    }else{

        return []
    }
  
}

const saveState =(state)=>{
sessionStorage.setItem('Data', JSON.stringify(state));
}
const store = createStore( dataReducer ,loadState());
store.subscribe(()=>{
saveState(store.getState())
})


export default store 