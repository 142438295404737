import React from 'react'
import { useState } from 'react';
import classes from './style/auth.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import BForm from 'react-bootstrap/Form';
import * as Yup from 'yup'
import axios from 'axios';
import Pop from './Pop';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Loader from './UI/Loader';

const Login = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [pop, setPop] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [isCompany , setIsCompany] = useState(false)
    const [Spinner,setSpinner] = useState(false)

    const closePop = () => {
        setPop(false)
    }
    const initialValues = {
        email: '',
        password: '',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().min(3).trim().email('Invalid email address').required(" Email field is required"),
        password: Yup.string().required("Password field is required")

    })
    const onSubmit = (data, { resetForm }) => {
        setSpinner(true)
        if(isCompany){
            data.email=data.email.trim();
            axios.post("https://api.matchmaker-eg.com/api/company/login", data).then((res) => {
                sessionStorage.setItem("tokenValue", res.data.token);
                sessionStorage.setItem("ID", res.data.company._id);
                sessionStorage.setItem("Type", "Company");
                setSpinner(false)
                dispatch({ type: "Login", login: true, id: res.data.id })
                history.push("/Company/Profile");
            }).catch((err) => {
                setSpinner(false)
                if (err.response.data.error === "Error: email is not verified ") {
                    setPop(true)
                }
                if (err.response.data.error === "Error: User is not found") {
                    setErrorMsg("User Doesn't Exist")
                }
                if (err.response.data.error === "Error: Password is incorrect") {
                    setErrorMsg("Password is incorrect")
                }
            })
        }else{
            data.email=data.email.trim();
            axios.post("https://api.matchmaker-eg.com/api/user/login", data).then((res) => {
                sessionStorage.setItem("tokenValue", res.data.token);
                sessionStorage.setItem("ID", res.data.user._id);
                sessionStorage.setItem("Type", "User");
                setSpinner(false)
                dispatch({ type: "Login", login: true, id: res.data.id })
                history.push("/Profile/myprofile");
            }).catch((err) => {
                setSpinner(false)
                if (err.response.data.error === "Error: email is not verified ") {
                    setPop(true)
                }
                if (err.response.data.error === "Error: User is not found") {
                    setErrorMsg("User Doesn't Exist")
                }
                if (err.response.data.error === "Error: Password is incorrect") {
                    setErrorMsg("Password is incorrect")
                }
            })
        }
        resetForm()

    }
    function clearError() {
        setErrorMsg("")
    }
    return (
        <div className={classes.container}>
            {
                pop &&
                <Pop type="not" closePop={closePop} />
            }
            <div className={classes.center}>
                <div className={classes.box1}>
                    <h1>Login</h1>
                    <BForm>
                        <div key={`default-checkbox`} className="mb-3">
                            <BForm.Check
                                type='checkbox'
                                id={`default-checkbox`}
                                label={`Login as a company`}
                                onChange={(e)=>{
                                    setIsCompany(e.target.checked)
                                }}
                            />
                        </div>
                    </BForm>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        <Form>
                            <div className={classes.fieldContainer}>
                                <Field className={classes.field} name='email' placeholder="Email address" autoComplete="off" />
                                <label className={classes.label}> Email address</label>
                                <ErrorMessage name='email' component="span" />
                            </div>
                            <div className={classes.fieldContainer}>
                                <Field className={classes.field} type="password" name='password' placeholder="Password" autoComplete="off" />
                                <label className={classes.label}> Password</label>
                                <ErrorMessage name='password' component="span" />
                            </div>
                            <div className={classes.errorMsg}>{errorMsg}</div>
                            <div className={classes.btn}>
                                <button onClick={clearError} type='submit'>Login</button>
                            </div>
                            <div className={classes.forgotPass}>
                                <p onClick={props.forgetPassHandler}>Forgot password?</p>
                            </div>

                        </Form>
                    </Formik>

                </div>
                <div className={classes.box2}>
                    <p>Don't have an account? <span onClick={props.signupHandeler}> Sign up</span></p>
                    {Spinner?<div className={classes.loader}> <Loader/> </div>:null}
                </div>
            </div>
        </div>
    )
}

export default Login
