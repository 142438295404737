import React from 'react'
import classes from './style/nav.module.css'
import { useState } from 'react'
import img from '../assets/Logo/Logo2.png'
import { Link, NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector} from 'react-redux'

const Nav = () => {
    const [active,setActive] = useState("sleepOptions")
    const Data=  useSelector(state => state);
    let type = sessionStorage.getItem("Type");

    const navHandeler =()=>{
        if(active==='optionsActive'){
            setActive('sleepOptions');
        }else{
            setActive('optionsActive')
        }
    }

    



  return (
    <div className={classes.nav}>
        <div className={classes.logoContainer}>
            <div className={classes.imgContainer}>
            <Link to="/"><img src={img} alt="logo"/></Link>
            </div>
        </div>
    
       {
        

            <div className={classes.navOptions}>
            <div className={`${classes[active]} ${classes.options}`}>
            <ul className={classes.links}>
                <li onClick={navHandeler} className={classes.op}>
                <NavLink to="/" exact activeClassName={classes.activeLink}>  Home  </NavLink>
                </li>
                <li  onClick={navHandeler} className={classes.op}>
                <NavLink  to="/about" activeClassName={classes.activeLink}> About ISI </NavLink>
                </li>
                {
                    Data.login ?
                    <li  onClick={navHandeler} className={classes.login}>
                        {type=="User"?<NavLink to="/Profile/myprofile" activeClassName={classes.activeLinkL} className={classes.loginL}> Profile </NavLink>:
                        <NavLink to="/Company/Profile" activeClassName={classes.activeLinkL} className={classes.loginL}> Profile </NavLink>}
                    </li>
                    :
                    <li  onClick={navHandeler} className={classes.login}>
                    <NavLink to="/auth" activeClassName={classes.activeLinkL} className={classes.loginL}> Login </NavLink>
                    </li>
                }
              
             
                
              
                
            </ul>
            </div>
           
        </div>
       }
        {active==='sleepOptions'? null:<div className={classes.back} onClick={navHandeler}></div>}
        <div className={classes.bur} onClick={navHandeler}>
            {active==='sleepOptions'? <MenuIcon style={{ fontSize: 40 }} /> : <ClearIcon style={{ fontSize: 40 }}/>}
        </div>
           
    </div>
  )
}

export default Nav
