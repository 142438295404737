import classes from './style/userform.module.css';
import React, { useState} from 'react';
import {Formik, Field, ErrorMessage, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import img1 from "../assets/profileform1.png"
import img2 from "../assets/profileform2.png"
import img3 from "../assets/profileform3.png"
import axios from 'axios';
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import SuccessModal from './SuccessModal.js';



function UserForm () {   
    function scrollUp()
    {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
const history = useHistory();
 
const initialValues = {
    //Basic Info
    name:"",
    nationalID: "",
    dateOfBirth: "",
    address: "",
    country: "",

    //Conact info
    mobileNO: "+20 ",
    wemail: "",

    //Education
    HLOfEdu: "",
    faculty: "",
    otherf: "",

    //Work Experience
    jobPosition: "",
    yearsOfExp: "",
    job: [
        {
            jobtitle: "",
            role: "",
            hiringbody: "",
            startDate: "",
            endDate: "",
            description: "",
        },
    ],
    //Certificates
    certificates: [
        {
            name: "",
            body: "",
        },
      
    ],

    //Internships
    internships: [
        {
            position: "",
            place: "",
            startDate: "",
            endDate: "",
            description: "",
        }
    ],

    //General Skills
    generalSkills: [
        {
            name: ""
        },
        {
            name: ""
        }

    ],
    technicalSkills: [
        {
            name: ""
        },
        {
            name: ""
        }
    ],
    longTermGoals: "",
    Motivation: "",

    CV: "",
}
const validationSchema = Yup.object().shape({

name: Yup.string().min(3).required("Name field is required"),
nationalID: Yup.string().min(14).max(14).required("National ID field is required"),
dateOfBirth: Yup.string().required("Birth date field is required"),
address: Yup.string().required("Address is required"),
country: Yup.string().required("Country is required"),
/*pic: Yup.string().required("Profile picture is required!!"),*/
 mobileNO: Yup.string().min(13).max(15).required("Phone number field is required"),
// // wemail: Yup.string().email("Enter valid email").required("Email field is required"),
CV: Yup.string().required('CV field is required'),
HLOfEdu: Yup.string().required("Education field is required"),
faculty: Yup.string().required("Faculty field is required"),
certificates: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name required"),
      body: Yup.string().required("this field is required")
       
    })
  ),
  technicalSkills: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Field required"),
  
       
    })
  ),
  generalSkills: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Field is required"),
    })
  ),
  
   jobPosition: Yup.string().min(3).required("Your job position is required!!"),
   yearsOfExp:  Yup.string().required("Your year of Experience is required!!"),
   job: Yup.array().of(
    Yup.object().shape({
        jobtitle: Yup.string().required("This field is required!!") ,
        role: Yup.string().required("This field is required!!") ,
        hiringbody: Yup.string().required("This field is required!!") ,
        startDate: Yup.string().required("This field is required!!"),
        endDate: Yup.string().required("This field is required!!"),
        // description: Yup.string().required("This field is required!!"),
    })
),
  // internships: Yup.array().of(
  //   Yup.object().shape({
  //       position: Yup.string().required("This field is required!!"),
  //       place: Yup.string().required("This field is required!!"),
  //       startDate: Yup.string().required("This field is required!!"),
  //       endDate: Yup.string().required("This field is required!!"),
  //       description: Yup.string().required("This field is required!!"),
  //   })
  // ),
  longTermGoals: Yup.string().max(150).required("This field is required!!"),
  Motivation: Yup.string().max(150).required("This field is required!!"),
})
    

const onformSubmit = (data)=>{
    axios.put(`https://api.matchmaker-eg.com/api/user`, data
    ,{ headers: {Authorization: `Bearer ${sessionStorage.getItem("tokenValue")}`}}).then((res)=>{
        console.log(res)
        setfinished(1)
    }).catch((err) => {
        console.log(err)
    })

   //history.push("/Profile/myprofile")
}

const [finished, setfinished] = useState(0);
const country = ["Afghanistan",
"Albania",
"Algeria",
"Andorra",
"Angola",
"Antigua & Deps",
"Argentina",
"Armenia",
"Australia",
"Austria",
"Azerbaijan",
"Bahamas",
"Bahrain",
"Bangladesh",
"Barbados",
"Belarus",
"Belgium",
"Belize",
"Benin",
"Bhutan",
"Bolivia",
"Bosnia Herzegovina",
"Botswana",
"Brazil",
"Brunei",
"Bulgaria",
"Burkina",
"Burundi",
"Cambodia",
"Cameroon",
"Canada",
"Cape Verde",
"Central African Rep",
"Chad",
"Chile",
"China",
"Colombia",
"Comoros",
"Congo",
"Congo {Democratic Rep}",
"Costa Rica",
"Croatia",
"Cuba",
"Cyprus",
"Czech Republic",
"Denmark",
"Djibouti",
"Dominica",
"Dominican Republic",
"East Timor",
"Ecuador",
"Egypt",
"El Salvador",
"Equatorial Guinea",
"Eritrea",
"Estonia",
"Ethiopia",
"Fiji",
"Finland",
"France",
"Gabon",
"Gambia",
"Georgia",
"Germany",
"Ghana",
"Greece",
"Grenada",
"Guatemala",
"Guinea",
"Guinea-Bissau",
"Guyana",
"Haiti",
"Honduras",
"Hungary",
"Iceland",
"India",
"Indonesia",
"Iran",
"Iraq",
"Ireland {Republic}",
"Italy",
"Ivory Coast",
"Jamaica",
"Japan",
"Jordan",
"Kazakhstan",
"Kenya",
"Kiribati",
"Korea North",
"Korea South",
"Kosovo",
"Kuwait",
"Kyrgyzstan",
"Laos",
"Latvia",
"Lebanon",
"Lesotho",
"Liberia",
"Libya",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Macedonia",
"Madagascar",
"Malawi",
"Malaysia",
"Maldives",
"Mali",
"Malta",
"Marshall Islands",
"Mauritania",
"Mauritius",
"Mexico",
"Micronesia",
"Moldova",
"Monaco",
"Mongolia",
"Montenegro",
"Morocco",
"Mozambique",
"Myanmar, {Burma}",
"Namibia",
"Nauru",
"Nepal",
"Netherlands",
"New Zealand",
"Nicaragua",
"Niger",
"Nigeria",
"Norway",
"Oman",
"Pakistan",
"Palau",
"Palestine",
"Panama",
"Papua New Guinea",
"Paraguay",
"Peru",
"Philippines",
"Poland",
"Portugal",
"Qatar",
"Romania",
"Russian Federation",
"Rwanda",
"St Kitts & Nevis",
"St Lucia",
"Saint Vincent & the Grenadines",
"Samoa",
"San Marino",
"Sao Tome & Principe",
"Saudi Arabia",
"Senegal",
"Serbia",
"Seychelles",
"Sierra Leone",
"Singapore",
"Slovakia",
"Slovenia",
"Solomon Islands",
"Somalia",
"South Africa",
"South Sudan",
"Spain",
"Sri Lanka",
"Sudan",
"Suriname",
"Swaziland",
"Sweden",
"Switzerland",
"Syria",
"Taiwan",
"Tajikistan",
"Tanzania",
"Thailand",
"Togo",
"Tonga",
"Trinidad & Tobago",
"Tunisia",
"Turkey",
"Turkmenistan",
"Tuvalu",
"Uganda",
"Ukraine",
"United Arab Emirates",
"United Kingdom",
"United States",
"Uruguay",
"Uzbekistan",
"Vanuatu",
"Vatican City",
"Venezuela",
"Vietnam",
"Yemen",
"Zambia",
"Zimbabwe",
];


const HLedu = ["Undergraduate student", "Non-faculty graduate", "Faculty degree", "Post graduate degree"];
const fac = ["Engineering", "Medicine", "Pharmacy", "Veterinary Medicine", "Dentistry", "Commerce", "Arts", "Education",  "Business administration", "Law", "Science", "Nursing", "Physical Education", "Computers and Information", "Economics and Political Sciences", "Media", "Physical Therapy", "Agriculture", "Applied Health Sciences Technology", "Kindergarten", "Applied Arts", "Al-Alsun", "Social Work", "Earth Sciences", "Tourism and Hotels", "Special Needs Science", "Navigation Sciences and Space Technology", "Other"];
const jobp = ["Architecture and Construction", "Agriculture", "Education and Training", "Design", "Manufacturing", "Health Services", "Medicine", "Fitness and Beauty", "Banking", "Trading", "Fund Raising", "law", "Advertisement and Promotion", "Marketing", "International affairs", "Business Management", "Real Estate", "Data Management", "Mass Communication", "Pharmacy and Drug Industry", "Tourism", "Arts and Music", "Media", "Press", "Transportation", "Academia", "Research", "Innovation and Technology Transfer", "Entrepreneurship", "Digital Services", "Food industry", "Human Resources", "Fashion", "Other"];
const yexp = ["<2 years", "2-5 years", ">5 years", ">10 years"];
const rol = ["Management", "Executive", "Administrative", "Technical", "Consultation", "Assistant"];
const hiringb = ["Free Lancer", "Private Business", "Non-Governmental Organization", "Governmental Organization"]

function dropDownMenu(option,index) {
    return (
        <option  key = {index}className={classes.option} value={option}>{option}</option>
    )
}
    function scrollUp()
    {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }


    return (
        <div className={classes.body}>

        <Formik initialValues={initialValues}  validationSchema={validationSchema} onSubmit={values=>onformSubmit(values)}>
              {
                ({values})=>
              
                <Form className={classes.shape}>
                <div className={classes.head}>
                    <div className={classes.fimg}>
                        <li><img src={img1} alt="User profile1"/></li>
                        <li><img src={img2} alt="User profile1"/></li>
                    </div>
                    <h3> Fill in your Personal Information <br/> So Companies can Find you Easily! </h3>
                    <h3 style={{textAlign:"center"}}> All fileds marked by an asterisk (*) are required</h3> 
                </div>
                <div className={classes.userbox}>
                    <div className={classes.left}>
                        <h1> Basic Info </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Name as in national ID</label>
                        <Field className={classes.input} type="text" name="name" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='name' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*National ID</label>
                        <Field className={classes.input} type="text" name="nationalID" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='nationalID' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Birth date</label>
                        <Field className={classes.input} type="date" name="dateOfBirth" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='dateOfBirth' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Country</label>
                        <Field as="select" className={classes.input} type="text" name="country" placeholder="Type here">
                            <option value="" disabled="disabled">Select your country</option>
                            {country.map((g, index)=> dropDownMenu(g,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='country' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Full Address</label>
                        <Field className={classes.input} type="text" name="address" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='address' component='span'/>
                    </div>
                    </div>
                </div>
                
                <div className={classes.userbox} style={{minHeight:200}}>
                    <div className={classes.left}>
                        <h1> Contact Info </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Mobile number</label>
                        <Field className={classes.input} type="tel" name="mobileNO" />
                        <ErrorMessage className={classes.Err} name="mobileNO" component='span'/>
                    </div>
                    {/*
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Working Mail</label>
                        <Field className={classes.input} type="text" name="wemail" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='wemail' component='span'/>
                    </div>
                    */}
                    </div>
                </div>

                <div className={classes.userbox} style={{minHeight: 200}}>
                    <div className={classes.left}>
                        <h1> Education </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Highest level of Education</label>
                        <Field as="select" className={classes.input} type="text" name="HLOfEdu" placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {HLedu.map((h, index)=> dropDownMenu(h,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='HLOfEdu' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Faculty</label>
                        <Field as="select" className={classes.input} type="text" name="faculty" placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {fac.map((f, index)=> dropDownMenu(f,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='faculty' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>If other specify</label>
                        <Field className={classes.input} type="text" name="otherf" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='otherf' component='span'/>
                    </div>
                    </div>
                </div>

                
                
                <div className={classes.userbox} style={{minHeight: 350}}>
                    <div className={classes.left}>
                        <h1> Certificates </h1>
                    </div>
                    <div className={classes.right}>
                    <FieldArray
                        name="certificates"
                        render={arrayHelpers => {
                        const certificates = values.certificates;
              return (
                <div className={classes.arrayConatiner}>
                  {certificates && certificates.length > 0
                    ? certificates.map((user, index) => (
                        <div key={index} className={classes.contFix}>
                            <div className={classes.inputbox}>
                            <label className={classes.label}>*Certification Name</label>
                            <Field className={classes.input} type="text" name={`certificates.${index}.name`} placeholder="Type here"/>
                            <ErrorMessage className={classes.Err} name={`certificates.${index}.name`} component='span'/>

                            <Field as='textarea' className={classes.inputL} type="text" name={`certificates.${index}.body`} placeholder="Description..."/>
                            <ErrorMessage className={classes.Err} name={`certificates.${index}.body`} component='span'/>
                            </div>

                          <button className={classes.remove}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            < DeleteIcon className={classes.clear} sx={{color:'#3B55FF', fontSize:20}}/>
                          </button>
                          <br />
                          <br />
                        </div>
                      ))
                    : null}
                  <button
                    className={classes.add}
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        email: ""
                      })
                    } // insert an empty string at a position
                  >
                    <p className={classes.labeladd}>Add Certificate</p>
                    +
                  </button>
                </div>
              );
            }}
          />
                    </div>
                </div>


                <div className={classes.userbox} style={{minHeight: 550}}>
                    <div className={classes.left}>
                        <h1> Internships </h1>
                    </div>
                    <div className={classes.right}>
                    
                    <FieldArray
                        name="internships"
                        render={arrayHelpers => {
                        const internships = values.internships;
              return (
                <div className={classes.arrayConatiner}>
                  {internships && internships.length > 0
                    ? internships.map((internships, index) => (
                        <div key={index} className={classes.contFix}>
                           
                        <div className={classes.inputbox}>
                        <label className={classes.label}>*Position</label>
                        <Field className={classes.input} type="text" name={`internships.${index}.position`} placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name={`internships.${index}.position`}  component='span'/>

                        <div className={classes.sedate}>
                            <div className={classes.date}>
                                <label className={classes.label}>*Start date</label>
                                <Field className={classes.input} type="date" name={`internships.${index}.startDate`} placeholder="MM/DD/YY" style={{width: 120}}/>
                        <ErrorMessage className={classes.Err} name={`internships.${index}.startDate`}  component='span'/>

                            </div>

                            <div className={classes.date}>
                                <label className={classes.label}>*End date</label>
                                <Field className={classes.input} type="date" name={`internships.${index}.endDate`} placeholder="MM/DD/YY" style={{width: 120}}/>
                        <ErrorMessage className={classes.Err} name={`internships.${index}.endDate`}  component='span'/>

                            </div>
                        </div>
                        <label className={classes.label}>*Place</label>
                        <Field className={classes.input} type="text" name={`internships.${index}.place`} placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name={`internships.${index}.place`}  component='span'/>
                        
                        <Field as="textarea" className={classes.inputL} type="text" name={`internships.${index}.description`} placeholder="Describe your training ..."/>
                        <ErrorMessage className={classes.Err} name={`internships.${index}.description`}  component='span'/>

                        </div>
                          <button
                            className={classes.remove}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            < DeleteIcon classname={classes.clear} sx={{color:'#3B55FF', fontSize:15}}/>
                          </button>
                          
                        </div>
                      ))
                    : null}
                  <button
                    className={classes.add}
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        email: ""
                      })
                    } // insert an empty string at a position
                  > <p className={classes.labeladd}>Add Internship</p>
                    +
                  </button>
                </div>
              );
            }}
          />
        </div>
    </div>

                
                <div className={classes.userbox} style={{minHeight: 850}}>
                    <div className={classes.left}>
                        <h1> General <br/> Skills </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Skills</label>
                        <FieldArray
                        name="generalSkills"
                        render={arrayHelpers => {
                        const generalSkills = values.generalSkills;
              return (
                <div className={classes.arrayConatiner2}>
                  {generalSkills && generalSkills.length > 0
                    ? generalSkills.map((generalSkills, index) => (
                        <div key={index} className={classes.contFix}>
                           
                          <div>
                          <Field className={classes.input} type="text" name={`generalSkills.${index}.name`} placeholder="Type here"/>

                            <button
                            className={classes.remove}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                            < DeleteIcon classname={classes.clear} sx={{color:'#3B55FF', fontSize:15}}/>
                            </button>
                          </div>
                          <ErrorMessage className={classes.Err} name={`generalSkills.${index}.name`}component='span'/>

                         
                        </div>
                      ))
                    : null}
                  <button
                    className={classes.add}
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        email: ""
                      })
                    } // insert an empty string at a position
                  >
                    +
                  </button>
                </div>
              );
            }}
          />

        </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Technical Skills</label>

                        <FieldArray
                        name="technicalSkills"
                        render={arrayHelpers => {
                        const technicalSkills = values.technicalSkills;
              return (
                <div className={classes.arrayConatiner2}>
                  {technicalSkills && technicalSkills.length > 0
                    ? technicalSkills.map((technicalSkills, index) => (
                        <div key={index} className={classes.contFix}>
                           
                          <div>
                          <Field className={classes.input} type="text" name={`technicalSkills.${index}.name`} placeholder="Type here"/>

                                <button
                                className={classes.remove}
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                < DeleteIcon classname={classes.clear} sx={{color:'#3B55FF', fontSize:15}}/>
                                </button>
                            </div>
                          <ErrorMessage className={classes.Err} name={`technicalSkills.${index}.name`}component='span' />

                          
                        </div>
                      ))
                    : null}
                  <button
                    className={classes.add}
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        email: ""
                      })
                    } // insert an empty string at a position
                  >
                    +
                  </button>
                </div>
              );
            }}
          />

                       
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Your Long Term Career Goals</label>
                        <Field as='textarea' className={classes.inputS} type="text" name="longTermGoals" placeholder="Short Answer 50 words limit ..." style={{minHeight:200}}/>
                        <ErrorMessage className={classes.Err} name='longTermGoals' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*What motivates you at work?</label>
                        <Field as='textarea' className={classes.inputS} type="text" name="Motivation" placeholder="Short Answer 50 words limit ..." style={{minHeight:200}}/>
                        <ErrorMessage className={classes.Err} name='Motivation' component='span'/>
                    </div>
                    </div>
                </div>


                <div className={classes.userbox} style={{minHeight: 700}}>
                    <div className={classes.left}>
                        <h1>Work <br/> Experience </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Professional fields or Industries</label>
                        <Field as="select" className={classes.inputJ} type="text" name="jobPosition" placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {jobp.map((j, index)=> dropDownMenu(j,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='jobPosition' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>*Total years of Experience</label>
                        <Field as="select" className={classes.inputJ} type="text" name="yearsOfExp" placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {yexp.map((y, index)=> dropDownMenu(y,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='yearsOfExp' component='span'/>
                    </div>
                    <h3 className={classes.job}> Job Positions </h3>

                    <FieldArray
                        name="job"
                        render={arrayHelpers => {
                        const job = values.job;
              return (
                <div className={classes.arrayConatiner}>
                  {job && job.length > 0
                    ? job.map((job, index) => (
                        <div key={index} className={classes.contFix}>
                            <div className={classes.inputbox}>

                        <label className={classes.label}>*Job title</label>
                        <Field className={classes.input} type="text" name={`job.${index}.jobtitle`} placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name={`job.${index}.jobtitle`}  component='span'/>
                        <br/> 

                        <label className={classes.label}>*Role</label>
                        <Field as="select" className={classes.input} type="text" name={`job.${index}.role`} placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {rol.map((r, index)=> dropDownMenu(r,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name={`job.${index}.role`}  component='span'/>
                        <br/>

                        <label className={classes.label}>*Hiring body</label>
                        <Field as="select" className={classes.input} type="text" name={`job.${index}.hiringbody`} placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {hiringb.map((h, index)=> dropDownMenu(h,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name={`job.${index}.hiringbody`}  component='span'/>
                        <br/>

                        <div className={classes.sedate}>
                            <div className={classes.date}>
                                <label className={classes.label}>*Start date</label>
                                <Field className={classes.input} type="date" name={`job.${index}.startDate`} placeholder="MM/DD/YY" style={{width: 120}}/>
                        <ErrorMessage className={classes.Err} name={`job.${index}.startDate`}  component='span'/>

                            </div>

                            <div className={classes.date}>
                                <label className={classes.label}>*End date</label>
                                <Field className={classes.input} type="date" name={`job.${index}.endDate`} placeholder="MM/DD/YY" style={{width: 120}}/>
                        <ErrorMessage className={classes.Err} name={`job.${index}.endDate`}  component='span'/>

                            </div>
                        </div>
                        <Field as="textarea" className={classes.inputL} type="text" name={`job.${index}.description`} placeholder="Description..."/>
                        <ErrorMessage className={classes.Err} name={`job.${index}.description`}  component='span'/>
                       
                            </div>
                            
                            <button
                            className={classes.remove}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            < DeleteIcon classname={classes.clear} sx={{color:'#3B55FF', fontSize:15}}/>
                          </button>
                          <br />
                          <br />
                        </div>
                      ))
                    : null}
                  <button
                    className={classes.add}
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        email: ""
                      })
                    } // insert an empty string at a position
                  >
                    <p className={classes.labeladd}>Add job</p>
                    +
                  </button>
                </div>
              );
            }}
          />
        </div>
    </div>

        
        
        <div className={classes.userbox} style={{minHeight:200}}>
                <div className={classes.left}>
                    <h1> Upload <br/>your CV </h1>
                </div>
                <div className={classes.right}>
                <div className={classes.inputbox}>
                    <label className={classes.label}>Link of the CV (Google drive)</label>
                    <Field className={classes.inputJ} type="text" name="CV" placeholder="Type here"/>
                    <ErrorMessage className={classes.Err} name='CV' component='span'/>
                </div>
                </div>
            </div>



                <div className={classes.head}>
                    <h2> YOU ARE READY TO SEE YOUR PROFILE! </h2>
                    <div className={classes.Limg}>
                        <img src={img3} alt="User profile1"/>
                    </div>
                </div>
                    <div className={classes.f}>

                            <button type="submit">
                                Submit
                            </button>
                     </div>
                </Form> 
                }
      </Formik>

      {finished?<SuccessModal msg="Profile" link="/Profile/myprofile"/>:null}
        </div>
    );
}

export default UserForm;