import classes from './style/userform.module.css';
import React, { useState} from 'react';
import {Formik, Field, ErrorMessage, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import img1 from "../assets/Companyform1.png"
import img3 from "../assets/Companyform2.jpg"
import axios from 'axios';
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';



function CompanyForm () {   
    function scrollUp()
    {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
const history = useHistory();
 
const initialValues = {
    //Company basic info
    companyName: "",
    companyAddress: "",
    companyPhone: "",
    industry:"", 
    country:"", //Needed to be added
    URL:"",

    //Social Accounts
    facebook:"", //b
    linkedin:"", //b
    instagram:"", //b

    //Other Data
    companySize: "", 
    legalEntity:"",
    olegalEntity:"", //b
    commercialRegistrationNumber:"",
    registrationYear:"",

    //Contact person data 
    contactPersonData_FullName: "",
    contactPersonData_IDNumber: "",
    contactPersonData_PositionInTheCompany: "",
    contactPersonData_mobileNumber: "",
    contactPersonData_emailAddress:"",
    contactPersonData_availableServices: "", //Not needed
    contactPersonData_subscriptionOptions: "", //Not needed
}
const validationSchema = Yup.object().shape({
/**/
})
    

const onformSubmit = (data)=>{

    axios.put(`https://api.matchmaker-eg.com/api/company`, data
    ,{ headers: {Authorization: `Bearer ${sessionStorage.getItem("tokenValue")}`}}).then((res)=>{
        console.log(res)
    })


    history.push("/Company/Profile")

}

const country = ["Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua & Deps",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Rep",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo {Democratic Rep}",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland {Republic}",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea North",
    "Korea South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar, {Burma}",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "St Kitts & Nevis",
    "St Lucia",
    "Saint Vincent & the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome & Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];


const Csize = ["<10", ">10", ">50",">100", ">300", ">500"];
const leg = ["Corporation", "Cooperative", "Partnerships", "Sole Traders", "Limited Liability Company", "Non registered"];
function dropDownMenu(option,index) {
    return (
        <option  key = {index}className={classes.option} value={option}>{option}</option>
    )
}
    function scrollUp()
    {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }


    return (
        <div className={classes.body}>

        <Formik initialValues={initialValues}  validationSchema={validationSchema} onSubmit={values=>onformSubmit(values)}>
              {
                ({values})=>
              
                <Form className={classes.shape}>
                    <div className={classes.head}>
                        <div className={classes.Cimg}>
                            <img src={img1} alt="User profile1"/>
                        </div>
                        <h3> Fill in Company Information</h3>
                    </div>
                <div className={classes.userbox}>
                    <div className={classes.left}>
                        <h1> Company Data </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Company/Organisation Name</label>
                        <Field className={classes.input} type="text" name="companyName" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='companyName' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Company Address</label>
                        <Field className={classes.input} type="text" name="companyAddress" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='companyAddress' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Company Phone Number</label>
                        <Field className={classes.input} type="text" name="companyPhone" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='companyPhone' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Industry Type</label>
                        <Field className={classes.input} type="text" name="industry" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='industry' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Country</label>
                        <Field as="select" className={classes.input} type="text" name="country" placeholder="Type here">
                            <option value="" disabled="disabled">Select your country</option>
                            {country.map((g, index)=> dropDownMenu(g,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='country' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Official website URL</label>
                        <Field className={classes.input} type="text" name="URL" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='URL' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Company Size</label>
                        <Field as="select" className={classes.input} type="text" name="companySize" placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {Csize.map((h, index)=> dropDownMenu(h,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='companySize' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Legal Entity</label>
                        <Field as="select" className={classes.input} type="text" name="legalEntity" placeholder="Type here">
                          <option value="" disabled="disabled">Select from list ...</option>
                          {leg.map((f, index)=> dropDownMenu(f,index))}
                        </Field>
                        <ErrorMessage className={classes.Err} name='legalEntity' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Other Legal Entity (Specify)</label>
                        <Field className={classes.input} type="text" name="olegalEntity" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='olegalEntity' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Commercial Registration Number</label>
                        <Field className={classes.input} type="text" name="commercialRegistrationNumber" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='commercialRegistrationNumber' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Registration Year</label>
                        <Field className={classes.input} type="date" name="registrationYear" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='registrationYearf' component='span'/>
                    </div>
                    </div>
                </div>
                
                <div className={classes.userbox} style={{minHeight:200}}>
                    <div className={classes.left}>
                        <h1> Social <br/>  Accounts </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Facebook</label>
                        <Field className={classes.input} type="text" name="facebook" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='facebook' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Linkedin</label>
                        <Field className={classes.input} type="text" name="linkedin" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='linkedin' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Other Social Accounts</label>
                        <Field className={classes.input} type="text" name="instagram" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='instagram' component='span'/>
                    </div>
                    </div>
                </div>

                <div className={classes.userbox}>
                    <div className={classes.left}>
                        <h1> Contact <br/> Person Data </h1>
                    </div>
                    <div className={classes.right}>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Full Name</label>
                        <Field className={classes.input} type="text" name="contactPersonData_FullName" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='contactPersonData_FullName' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>ID Number</label>
                        <Field className={classes.input} type="text" name="contactPersonData_IDNumber" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='contactPersonData_IDNumber' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Position in Company</label>
                        <Field className={classes.input} type="text" name="contactPersonData_PositionInTheCompany" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='contactPersonData_PositionInTheCompany' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Mobile Number</label>
                        <Field className={classes.input} type="text" name="contactPersonData_mobileNumber" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='contactPersonData_mobileNumber' component='span'/>
                    </div>
                    <div className={classes.inputbox}>
                        <label className={classes.label}>Email Address</label>
                        <Field className={classes.input} type="text" name="contactPersonData_emailAddress" placeholder="Type here"/>
                        <ErrorMessage className={classes.Err} name='contactPersonData_emailAddress' component='span'/>
                    </div>
                    </div>
                </div>

                <div className={classes.head}>
                    <h2> YOU ARE READY TO SEE YOUR PROFILE! </h2>
                    <div className={classes.Limg}>
                        <img src={img3} alt="User profile1"/>
                    </div>
                </div>
                    <div className={classes.f}>
                            <button type="submit">
                                Submit
                            </button>
                     </div>
                </Form> 
                }
      </Formik>

        </div>
    );
}

export default CompanyForm;