import React from 'react'
import classes from './style/sidebar.module.css'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import StarsIcon from '@mui/icons-material/Stars';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {  useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useState } from 'react'
import { NavLink } from 'react-router-dom'


const SideBarProfile = () => {
  const dispatch = useDispatch()
  const history = useHistory();
  const signOutHandeler =()=>{
    sessionStorage.removeItem("tokenValue") 
    sessionStorage.removeItem("ID") 
    dispatch({type: "signOut"})
    history.push("/");
  } 
  const [active,setActive] = useState('sleepOptions')
  const navHandeler =()=>{
    setActive('optionsActive')
  }
  const closeNav = () =>{
  setActive('sleepOptions')
  }

  return (
  
    <div className={active === 'optionsActive'?  classes.expand : classes.sidebar}>
        {
          <div className={classes.navOptions}>
            <div className={`${classes[active]} ${classes.options}`}>
                  <ul className={classes.links}>
                    <li onClick={closeNav} style={{marginTop: "20%"}}>
                      <NavLink  to="/Profile/myprofile" activeClassName={active === 'optionsActive'?  classes.activeLink : classes.activeShrink} className={classes.op} > 
                        <AccountBoxIcon className={classes.icon} fontSize='large'/>
                        <p className={active === 'optionsActive'?  classes.appear : classes.disappear}> Profile</p> 
                      </NavLink>
                    </li>
                    <li onClick={closeNav}>
                      <NavLink  to="/Profile/Avatar" activeClassName={active === 'optionsActive'?  classes.activeLink : classes.activeShrink} className={classes.op}> 
                        <StarsIcon className={classes.icon} fontSize='large'/>
                        <p className={active === 'optionsActive'?  classes.appear : classes.disappear}>My Avatar</p>
                      </NavLink>
                    </li>
                    <li onClick={closeNav}>
                      <NavLink  to="/Profile/Bill" activeClassName={active === 'optionsActive'?  classes.activeLink : classes.activeShrink} className={classes.op}>
                        <ReceiptLongIcon className={classes.icon} fontSize='large'/>
                        <p className={active === 'optionsActive'?  classes.appear : classes.disappear}>Billing Info</p>
                      </NavLink>
                    </li>
                    <li onClick={signOutHandeler}>
                      <a className={classes.op}>
                        <LogoutIcon className={classes.icon} fontSize='large'/>
                        <p className={active === 'optionsActive'?  classes.appear : classes.disappear}>Log Out</p>
                      </a>
                    </li>
                  </ul>
              </div>
          </div>
        }
          {active==='sleepOptions'? null:<div className={classes.back} onClick={navHandeler}></div>}
            <div className={classes.bur} >
                {active==='sleepOptions'? <ArrowForwardIosIcon  onClick={navHandeler} className={classes.arrow} /> : <ArrowBackIosNewIcon onClick={closeNav} className={classes.arrow}/>}
            </div>
    </div>
  
)
}
export default SideBarProfile