import React from 'react'
import classes from './card.module.css'
const Card = (props) => {
  return (
    <div className={classes.container}>
        <div className={classes.card}>
            <div className={classes.image}>
                <img src={props.img} alt="gg"></img>
            </div>
            <h3 className={classes.title}> {props.title}</h3>
        </div>
    </div>
  )
}

export default Card