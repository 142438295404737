import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Isi from "../components/Isi"
import About from '../components/About'
import Works from '../components/Works'
import Works2 from '../components/Works2'


const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }); 
  return (
    <div>
        <Layout/>
        <About/>
        <Works/>
        <Works2/>
        <Isi/>
    </div>
  )
}

export default Home